export const START_TEST = 'START_TEST'
export const END_TEST = 'END_TEST'

export const startTest = () => ({
  type: START_TEST
})

export const endTest = () => ({
  type: END_TEST
})
